import React from 'react';
import SomeTimes from '../../utils/SomeTimes.jsx';

export const Closed = () =>
  <SomeTimes from="2024-03-15 09:00:00" to="2024-03-19 23:59:59">
    <div className="home-banner-container">
      <div className="home-banner">
        <h2>Office Closure</h2>
        <h3>Monday 18th and Tuesday 19th March</h3>
        <p>
          The office will be closed on <span className="green">Monday 18th</span> and{' '}
          <span className="green">Tuesday 19th March</span>{' '}
          because we'll be exhibiting at{' '}
          <a href="https://www.photographyshow.com/" className="">The Photography Show</a>.
          This means we are unable to dispatch items on these days.
        </p>
        <p>
          If you require an order to be delivered between between{' '}
          <span className="green">Monday 18th</span> and{' '}
          <span className="green">Wednesday 20th March</span>, you'll need to
          place your hire by <span className="green">2pm on Friday 15th March</span>.
          Our reception is still open, so this closure doesn't affect returning items.
        </p>
      </div>
    </div>
  </SomeTimes>

export default Closed


